import { injectable } from 'inversify'

@injectable()
export class HttpGateway {
  public get = async path => {
    const response = await fetch(path)
    const dto = response.json()
    return dto
  }

  public post = async (path, requestDto) => {
    const response = await fetch(path, {
      method: 'POST',
      body: JSON.stringify(requestDto),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const responseDto = response.json()
    return responseDto
  }
}
