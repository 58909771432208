import 'reflect-metadata'
import * as React from 'react'
import { Provider } from 'inversify-react'
import { CurrentPageComponent } from './app/Routing/CurrentPageComponent'
import { container } from './ioc'
import { createGlobalStyle } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { render } from 'react-dom'
import './styles.css'
const Global = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Varela+Round&display=swap');
  html, body, #root {
    margin: 0;
    height: 100vh;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    font-family: 'Varela Round', sans-serif;
  }
`

const App = () => (
  <Provider container={container}>
    <div>
      <Normalize />
      <Global />
      <CurrentPageComponent />
    </div>
  </Provider>
)

render(<App />, document.getElementById('root'))
