import 'reflect-metadata'
import { injectable, inject } from 'inversify'
import { RouteRegistrar } from './RouteRegistrar'
import { RouteUpdater } from './RouteUpdater'
import { Routes } from './Routes'
import { RoutingState } from './RoutingState'
import { Types } from 'app/Core/Types'

@injectable()
export class Router {
  @inject(RouteRegistrar)
  public routeRegistrar

  @inject(RouteUpdater)
  public routeUpdater

  @inject(Routes)
  public routes

  @inject(Types.IRouterGateway)
  public routerGateway

  @inject(RoutingState)
  public routingState

  public get currentRouteId() {
    return this.routingState.currentState.routeId
  }

  public registerRoutes = () => {
    let routeConfig = this.routeRegistrar.extractRoutes(this.routes.routes)
    this.routerGateway.registerRoutes(routeConfig)
    if (this.routingState.currentState.routeId === null) this.goToId('home', null, null)
  }

  public goToId = async (routeId, params, query) => {
    let routePath = this.routeUpdater.findRoute(routeId).routeDef.path

    if (query) {
      routePath = routePath + '?' + query
    }
    this.routerGateway.goToPath(routePath)
  }
}
