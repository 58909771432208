import * as React from 'react'
import { resolve } from 'inversify-react'
import { observer } from 'mobx-react'
import { HomePresenter } from './HomePresenter'
import { LeaderboardComponent } from 'app/Leaderboard/LeaderboardComponent'

@observer
export class HomeComponent extends React.Component {
  @resolve(HomePresenter)
  public presenter

  public render() {
    return (
      <div style={{margin:20}}>

        <img style={{width:50, paddingLeft:10}} src="https://kajabi-storefronts-production.global.ssl.fastly.net/kajabi-storefronts-production/themes/1537017/settings_images/1UxqoCceShyczTsmzsLy_logo.png"/>

        <h2>Logic Room - UI Academy Leaderboard</h2>

        <p>This is updated at 8am every morning.</p>

        <p>You will only show up on this leaderboard when you have:</p>

        <ul>
          <li>completed an assessment</li>
          <li>joined the community with the same email address that you are training with</li>
          <li>actually started training with us <a target="_blank" href="https://www.logicroom.co/courses">(hint)</a></li>
        </ul>

        <div>REMEMBER : if you change your email on either logicroom.co or slack then your results may not show! We don't support changes to email addresses 'yet'.</div>

        <br/>

        <LeaderboardComponent></LeaderboardComponent>
      </div>
    )
  }
}
