import 'reflect-metadata'
import { injectable, inject } from 'inversify'
import { observable, observe } from 'mobx'
import { Repository } from '../Core/Repository'

@injectable()
export class HomePresenter {
  @observable
  public viewModel = []

  @inject(Repository)
  public repository

  public load = async () => {
    var self = this
    observe(
      this.repository,
      'studentsResultsPm',
      obj => {
        self.viewModel = obj.newValue
      },
      true
    )
  }
}
