import 'reflect-metadata'
import { injectable, inject } from 'inversify'
import { observable, observe } from 'mobx'
import { Repository } from '../Core/Repository'

@injectable()
export class LeaderboardPresenter {
  @observable
  public viewModel = []

  @inject(Repository)
  public repository

  public load = async () => {
    const self = this
    observe(
      this.repository,
      'studentsResultsPm',
      obj => {
        self.viewModel = obj.newValue.map(pm => {
          return {
            communityMember: {
              name: pm.communityName,
              completions: pm.completions,
              certificateUrl: pm.certificateId && !!pm.hasAdded ? 'https://certificates.logicroom.co/' + pm.certificateId : null
            }
          }
        })
      },
      true
    )
    this.repository.load()
  }
}
