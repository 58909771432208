import 'reflect-metadata'
import { injectable, inject } from 'inversify'
import { Router } from './Router'
import { RoutingState } from './RoutingState'
import { computed, observable } from 'mobx'

@injectable()
export class CurrentPagePresenter {
  @inject(Router)
  public router

  @inject(RoutingState)
  public routingState

  @observable
  public loading

  @computed
  public get currentRouteId() {
    return this.routingState.currentState.routeId
  }

  public bootstrap = async () => {
    this.router.registerRoutes()
    this.loading = false
  }
}
