import 'reflect-metadata'
import * as React from 'react'
import { resolve } from 'inversify-react'
import { observer } from 'mobx-react'
import { LeaderboardPresenter } from './LeaderboardPresenter'
import { observe } from 'mobx'

@observer
export class LeaderboardComponent extends React.Component {
  @resolve(LeaderboardPresenter)
  public presenter

  public state: any

  constructor(props) {
    super(props)
    this.state = { shadowViewModel: [] }
  }

  public componentDidMount() {
    const self = this
    async function load() {
      observe(
        self.presenter,
        'viewModel',
        obj => {
          self.setState({ shadowViewModel: obj.newValue })
        },
        true
      )
      await self.presenter.load()
    }
    load()
  }

  public render() {
    return (
      <>
        <table>
          <thead>
          <tr style={{fontWeight:'bold', borderBottom: '3px black solid'}}>
            <td width={250}>Name</td>
              <td>UFT : <br/> Introduction Assessment</td>
              <td>UFT : <br/> Foundation Assessment</td>
              <td>UFT : <br/> Module 1 Assessment</td>
              <td>UFT : <br/> Module 2 Assessment</td>
              <td>UFT : <br/> Module 3 Assessment</td>
              <td>UFT : <br/> Module 4 Assessment</td>
              <td>UFT : <br/> Module 5 Assessment</td>
              <td>UFT : <br/> Module 6 Assessment</td>
              <td>IA : <br/> Module 1 Assessment</td>
              <td>IA : <br/> Module 2 Assessment</td>
              <td>IA : <br/> Module 3 Assessment</td>
              <td>IA : <br/> Module 4 Assessment</td>
              <td>IA : <br/> Module 5 Assessment</td>
              <td>IA : <br/> Module 6 Assessment</td>
            </tr>
          </thead>

          <tbody>
          {this.state.shadowViewModel.map((record, i) => {
            return (
              <tr key={i}>
                <td width={250}>{record.communityMember.name}</td>
                <td>{record.communityMember.completions.includes('UFT : Introduction Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('UFT : Foundation Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('UFT : Module 1 Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('UFT : Module 2 Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('UFT : Module 3 Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('UFT : Module 4 Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('UFT : Module 5 Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('UFT : Module 6 Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('IA : Module 1 Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('IA : Module 2 Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('IA : Module 3 Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('IA : Module 4 Assessment') && 'x'}</td>
                <td>{record.communityMember.completions.includes('IA : Module 5 Assessment') && 'x'}</td>
                <td>
                  {record.communityMember.completions.includes('IA : Module 6 Assessment') && (
                    <a target={'blank'} href={record.communityMember.certificateUrl}>x</a>
                  )}
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </>
    )
  }
}
