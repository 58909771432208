import { Container } from 'inversify'
import { RouterGateway } from 'app/Routing/RouterGateway'
import { RoutingState } from 'app/Routing/RoutingState'
import { Repository } from 'app/Core/Repository'
import { HttpGateway } from 'app/Core/HttpGateway'
import { Types } from 'app/Core/Types'

export const container = new Container({
  autoBindInjectable: true,
  defaultScope: 'Transient'
})

container
  .bind(Repository)
  .to(Repository)
  .inSingletonScope()
container
  .bind(Types.IServiceGateway)
  .to(HttpGateway)
  .inSingletonScope()
container
  .bind(Types.IRouterGateway)
  .to(RouterGateway)
  .inSingletonScope()
container
  .bind(RoutingState)
  .to(RoutingState)
  .inSingletonScope()
