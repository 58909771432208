import { injectable, inject } from 'inversify'
import { observable } from 'mobx'
import { Types } from '../Core/Types'

@injectable()
export class Repository {
  @observable
  public studentsResultsPm = []

  @inject(Types.IServiceGateway)
  public serviceGateway

  private url = process.env.NODE_ENV === 'development' ? 'http://localhost:4040/' : 'https://api.logicroom.co/'



  private targetOrder = [
    'UFT : Introduction Assessment',
    'UFT : Foundation Assessment',
    'UFT : Module 1 Assessment',
    'UFT : Module 2 Assessment',
    'UFT : Module 3 Assessment',
    'UFT : Module 4 Assessment',
    'UFT : Module 5 Assessment',
    'UFT : Module 6 Assessment',
    'IA : Module 1 Assessment',
    'IA : Module 2 Assessment',
    'IA : Module 3 Assessment',
    'IA : Module 4 Assessment',
    'IA : Module 5 Assessment'
  ]

  public load = async () => {
    const self = this
    const studentsDto = await this.serviceGateway.get(this.url + 'api/students')
    const leaderboardDataDto = await this.serviceGateway.get(this.url + 'api/leaderboardata')
    const retVal = studentsDto.result.map(studentDtoItem => {
      const completions = leaderboardDataDto.result.filter(completion => {
        return completion.id === studentDtoItem.id
      })
      let pm = {
        communityName: studentDtoItem.real_name,
        communityId: studentDtoItem.id,
        completions: completions.map(completion => {
          return completion.assessment
        }),
        certificateId: completions
          .map(completion => {
            return completion.certificateUuid
          })
          .filter(x => !!x),
        hasAdded: completions
          .map(completion => {
            return completion.hasAdded
          })
          .filter(x => !!x)
      }
      pm.completions.sort(function(a, b) {
        return self.targetOrder.indexOf(a) - self.targetOrder.indexOf(b)
      })
      return pm
    })

    retVal.sort((a, b) => {
      return b.completions.length - a.completions.length
    })

    this.studentsResultsPm = retVal
  }
}
