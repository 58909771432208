import 'reflect-metadata'
import { injectable, inject } from 'inversify'
import { Repository } from 'app/Core/Repository'

@injectable()
export class Routes {
  @inject(Repository)
  public repository

  public routes = [
    {
      routeId: 'home',
      routeDef: {
        path: '/app/home'
      },
      onEnter: () => {
        this.repository.load()
      }
    }
  ]
}
