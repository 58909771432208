import * as React from 'react'
import { resolve } from 'inversify-react'
import { CurrentPagePresenter } from './CurrentPagePresenter'
import { HomeComponent } from '../Home/HomeComponent'
import { observer } from 'mobx-react'
import { RoutingState } from './RoutingState'

@observer
export class CurrentPageComponent extends React.Component {
  @resolve(CurrentPagePresenter)
  public presenter

  @resolve(RoutingState)
  public routingState

  public componentDidMount() {
    this.presenter.bootstrap()
  }

  public render() {
    const renderedComponents = [
      {
        id: 'home',
        component: <HomeComponent key="page" />
      }
    ]

    return (
      <>
        {renderedComponents.map(current => {
          return this.presenter.currentRouteId === current.id && current.component
        })}
      </>
    )
  }
}
